/// <reference path="define/jquery/jquery.d.ts" />
/// <reference path="define/jqueryui/jqueryui.d.ts" />

/**
 * サンプルプログラム
 * @param v1
 * @param v2
 * @returns {number}
 */
function getNumber(v1:number, v2:number):number {
    return v1 * v2;
}

$(function(){
    //alert('hello typescript!! value=' + getNumber(10, 256));
    //
    //$('h1').on('click', function(){
    //    var current = $(this).html();
    //    $(this).html(current + ' clicked!!');
    //});
    //$({deg:0}).animate({deg:360}, {
    //    duration: 3000,
    //    progress: function(){
    //        $('.ferris-wheel').css({
    //            transform: 'rotate(' + this.deg + 'deg)'
    //        });
    //    }
    //});
});

// 背景の雲の制御
$(function(){

    // 雲クラス
    class Cloud {
        // コンストラクタ
        constructor(public jquery: JQuery, scroll_rate: number){
            this.target = jquery;
            this.initial_top = parseInt(jquery.css('top'));
            this.initial_left = parseInt(jquery.css('left'));
            this.scroll_rate = scroll_rate;
        }
        // 初期値(top)
        private initial_top:number;
        // 初期値(left)
        private initial_left:number;
        // 雲JQueryオブジェクト
        private target:JQuery;
        // スクロール率
        private scroll_rate:number;
        // 再配置処理
        public replace(window_width: number){
            var viewport = 1200;
            var work = (window_width - viewport) / 2;
            if(work < 0) return;
            this.target.css('left', this.initial_left + work);
        }
        // スクロール処理
        public scroll(value: number){
            this.target.css('top', this.initial_top - (value * this.scroll_rate));
        }
    }

    // ウインドウリサイズ時の処理
    function resize_window(){
        // ウインドウサイズ表示
        var window_width = $(window).width();
        for(var i = 0; i < clouds.length; i++){
            // 雲の位置を修正する(fixed表示なので)
            clouds[i].replace(window_width);
        }
    }

    // ウインドウスクロール時の処理
    function scroll_window(){
        var value = $(this).scrollTop();
        for(var i = 0; i < clouds.length; i++){
            // 雲の位置を修正する(fixed表示なので)
            clouds[i].scroll(value);
        }
    }

    // 雲座標初期値格納
    var clouds:Cloud[] = [];

    // 雲初期値取得
    function init_clouds(){
        // 雲の移動率用カウンタ
        var counter = 1;
        $('.background-clouds .background-cloud').each(function(){
            var cloud = new Cloud($(this), ((counter % 3) + 1) * 0.2);
            counter += 1;
            clouds.push(cloud);
        })
    }

    // ウインドウリサイズハンドラ設定
    $(window).resize(function(){
        resize_window();
    });

    // ウインドウスクロールハンドラ設定
    $(window).scroll(function(){
        scroll_window();
    });

    // 雲座標初期値取得
    init_clouds();
    // 画面描画時リサイズ処理呼び出し
    resize_window();
});
